<template>
    <div class="contentBorder_container">
        {{content}}
        <div class="contentBorder_icon_container">
            <div class="contentBorder_iconBlock">
                <div class="contentBorder_point"></div>
                <div class="contentBorder_icon">
                    <img :src="require(`@/assets/images/meeting/${iconName}`)" />
                </div>
                <div class="contentBorder_point"></div>
            </div>            
        </div>
    </div>
</template>
<script>
export default {
    name:"contentBorder",
    props:{
        iconName:{
            type:String,
            default:""
        },
        content:{
            type:String,
            default:""
        }
    },
    data(){
        return {
            data:{
                
            }
        }
    },
}
</script>
<style>
.contentBorder_container{
    position: relative;
    width: 100%;
    border-radius: 1vw;
    border: 1px solid #80b25f;
    padding: 10.4vw 3.2vw;
}
.contentBorder_icon_container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.contentBorder_iconBlock{
    position: absolute;
    top: -6.6vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(252, 245, 248 0);
    z-index: 100;
    width: 22.8vw;
    background:linear-gradient(#fcf8fa,#f2f9e3);
}
.contentBorder_point{
    background-color: #80b25f;
    width: 1.6vw;
    height: 1.6vw;
    border-radius: 0.8vw;
}
.contentBorder_icon{
    height: 13vw;
    width: 14.4vw;
    display: flex;
    justify-content: center;
}
.contentBorder_icon img{
    height: 100%;
    width: auto;
}
</style>